<template>
    <div class="model-upload-page">
<!--        <div class="title">-->
<!--            {{ title }}-->
<!--            <el-affix :offset="100" style="float: left; cursor: pointer" v-if="isEdit">-->
<!--                <span @click="handleClose">-->
<!--                    <el-button size="small">返回</el-button>-->
<!--                </span>-->
<!--            </el-affix>-->
<!--        </div>-->
<!--        <div class="title">{{  title }}</div>-->
        <ModelUploadCom :buttonString="buttonString" :title="title"></ModelUploadCom>
    </div>
</template>
<script>
import ModelUploadCom from "../../../components/ModelUploadCom";
export default {
    name: 'modelUploadPage',
    components: {ModelUploadCom},
    data() {
        return {
            title: '上传模型信息',
            buttonString:'保存并上传模型',
        };
    }
}
</script>
<style>
    .model-upload-page .title {
        width: 100%;
        text-align: center;
        height: 65px;
        line-height: 65px;
        border-bottom: 1px solid #dcdfe6;
        font-weight: 700;
        font-size: 18px;
        color: rgb(127, 127, 127);
    }
</style>